import React from 'react';
import PropTypes from 'prop-types';


export default class Photo extends React.Component {
    static propTypes = {
        image: PropTypes.string.isRequired,
    };

    render() {
        return (
            <a
                download
                href={`/${this.props.image}`}
                style={{
                    backgroundImage: `url(/thumb-${this.props.image})`,
                }}
            >
                {`Download ${this.props.image}`}
            </a>
        );
    }
}
