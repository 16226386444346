import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/docs/src/components/Guide.js"
import Button from 'components/Button.js';
import Photo from 'components/Photo.js';

export default ({components, ...props}) => <MDXTag name="wrapper" Layout={DefaultLayout} layoutProps={props} components={components}>


<MDXTag name="p" components={components}>{`Photography is the primary image device for HealthShare graphics and is sometimes supported by `}<MDXTag name="a" components={components} parentName="p" props={{"href":"/branding/iconography.html"}}>{`icons`}</MDXTag>{` and/or `}<MDXTag name="a" components={components} parentName="p" props={{"href":"/branding/illustration.html"}}>{`illustration.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`HealthShare photography focuses on `}<MDXTag name="strong" components={components} parentName="p">{`everyday people`}</MDXTag>{` doing relatable activities and generally enjoying being `}<MDXTag name="strong" components={components} parentName="p">{`healthy and happy.`}</MDXTag>{` Subjects `}<MDXTag name="strong" components={components} parentName="p">{`shouldn't be looking into the camera`}</MDXTag>{` and the general vibe of the image should be `}<MDXTag name="strong" components={components} parentName="p">{`warm and bright.`}</MDXTag>{` Ideally, photos are taken in `}<MDXTag name="strong" components={components} parentName="p">{`daytime and good weather.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`A realistic sample of the population and climate in Australia is key, with a `}<MDXTag name="strong" components={components} parentName="p">{`high ratio of lifestyle shots`}</MDXTag>{` relative to medical industry ones (which may be required for certain contexts).`}</MDXTag>
<MDXTag name="p" components={components}>{`Image colour correction can be automated. `}<MDXTag name="a" components={components} parentName="p" props={{"href":"/WarmPhotos.atn"}}>{`Download the Photoshop script.`}</MDXTag></MDXTag>
<MDXTag name="h2" components={components}>{`Image library`}</MDXTag>
<figure className="gallery">
    <Photo image="afro-woman-smiling-sun.jpg"/>
    <Photo image="asian-woman-laptop.jpg"/>
    <Photo image="baby-walking.jpg"/>
    <Photo image="business-meeting.jpg"/>
    <Photo image="family-having-fun-on-bed.jpg"/>
    <Photo image="healthy-woman-eating-salad.jpg"/>
    <Photo image="holding-fingers-baby.jpg"/>
    <Photo image="man-on-sofa-computer.jpg"/>
    <Photo image="man-touching-tablet.jpg"/>
    <Photo image="old-man-boy-playing-football-beach.jpg"/>
    <Photo image="old-woman-yoga.jpg"/>
    <Photo image="man-on-laptop.jpg"/>
    <Photo image="runner-outside-mountains.jpg"/>
    <Photo image="young-people-playing-on-beach.jpg"/>
    <Photo image="couple-on-couch.jpg"/>
    <Photo image="patient-doctor-hand-on-shoulder.jpg"/>
    <Photo image="practitioner-talking-old-lady.jpg"/>
    <Photo image="woman-under-blanket.jpg"/>
    <Photo image="gp-patient-consultation.jpg"/>
    <Photo image="group-doctors-watch-results.jpg"/>
    <Photo image="patient-woman-compasionate.jpg"/>
    <Photo image="two-woman-hugging.jpg"/>
    <Photo image="woman-looking-at-her-phone.jpg"/>
    <Photo image="family-having-fun-home.jpg"/>
    <Photo image="group-young-adults-beach.jpg"/>
    <Photo image="old-couple-beach.jpg"/>
    <Photo image="old-couple-beach-2.jpg"/>
    <Photo image="young-adult-female-kayak.jpg"/>
    <Photo image="woman-looking-out-window.jpg"/>
    <Photo image="two-ladies-doing-exercice.jpg"/>
    <Photo image="smiling-woman-sun.jpg"/>
    <Photo image="physio-old-lady.jpg"/>
    <Photo image="man-looking-factsheet.jpg"/>
    <Photo image="group-support-meeting.jpg"/>
    <Photo image="group-people-circle.jpg"/>
    <Photo image="asian-family-computer.jpg"/>
</figure>
<Button
    download
    href="https://healthshare.sharepoint.com/:u:/r/Shared%20Folder%20Large%20Files%20%20Do%20Not%20Sync/HS-Photo-Library.zip?csf=1&e=NavusC"
    label="Download HealthShare photo library"
/></MDXTag>

export const _frontmatter = {};

  